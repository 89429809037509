import React from "react";
import PropTypes from "prop-types";
import { StaticQuery, graphql } from "gatsby";
import Nav from "./nav";
import Seo from "./seo";

// import ContactForm from "./ContactForm";


const Layout = ({ children, seo }) => (
  <StaticQuery
    query={graphql`
      query {
        strapiHomepage {
          seo {
            metaTitle
            metaDescription
            
          }
        }
      }
    `}

    // shareImage {
    //   publicURL
    // }
    render={(data) => (
      <>
        <Seo seo={seo} />

        <div class="uk-position-relative uk-background-secondary">

          <Nav />
          <main uk-height-viewport="expand: true">{children}</main>
          {/* <ContactForm /> */}

          {/* uk-background-secondary */}
          <footer>
            <div class="uk-container uk-container-expand uk-background-muted">

              <div class="uk-child-width-1-2@s uk-text-center" data-uk-grid="true">
                <div>
                  <p id="companyDescription" class="uk-card uk-card-default uk-card-body uk-margin-top uk-margin-bottom">
                    logvoid uses natural language processing and AI to analyze how news outlets portray information that may subconsciously affect your perception of the media.
                    <br />
                    <br />
                    If there's specific news that you want us to cover, let us know through the form (we won't contact you via email, it's required for anti-spam).
                    <br />
                    <br />
                    For other enquiries, we can respond to you more directly through email: contact@logvoid.com. We're also on Twitter @logvoid.
                    </p>
                </div>
                <div>
                  <form
                    name="contact"
                    method="post"
                    action="/success/"
                    data-netlify="true"
                    data-netlify-honeypot="bot-field"
                  >
                    <input type="hidden" name="form-name" value="contact" />
                    <p hidden>
                      <label>
                        Don’t fill this out:{" "}
                        <input name="bot-field" />
                      </label>
                    </p>
                    <p id="companyDescription">
                      <label>
                        Your Name:
            <br />
                        <input type="text" name="name" />
                      </label>
                    </p>
                    <p id="companyDescription">
                      <label>
                        Your Email:
            <br />
                        <input type="email" name="email" />
                      </label>
                    </p>
                    <p id="companyDescription">
                      <label>
                        Message:
            <br />
                        <textarea name="message" />
                      </label>
                    </p>
                    <p id="companyDescription">
                      <button type="submit">Send</button>
                      {/* <input type="reset" value="Eraser" /> */}
                    </p>
                  </form>
                </div>

              </div>
            </div>

          </footer>
        </div>

      </>
    )}
  />
);

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
